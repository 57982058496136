import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import getThirdPartyLogo, { spritePath } from '@assets/logos';
import { orientationClassNames } from '@util/aspectRatio';

import * as styles from './ThirdPartyLogo.module.css';

const ThirdPartyLogo = ({ logoKey, className, style }) => {
  const logo = getThirdPartyLogo(logoKey);

  if (!logo) {
    return null;
  }

  const logoPath = `${spritePath}#${logoKey}`;

  const logoClassName = cn(
    orientationClassNames({
      width: logo?.width,
      height: logo?.height,
      square: styles.square,
      portrait: styles.portrait,
      landscape: styles.landscape,
    }),
    styles.root,
    className,
  );

  return (
    <>
      <svg
        className={logoClassName}
        style={style}
        viewBox={`0 0 ${logo.width} ${logo.height}`}
      >
        <use xlinkHref={logoPath} width={logo.width} height={logo.height} />
      </svg>
    </>
  );
};

ThirdPartyLogo.propTypes = {
  logoKey: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ThirdPartyLogo;
