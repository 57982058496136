import { KEYWORD_OPERATOR } from '@constants/search';
import { removeEmptyKeys } from '@util/functions';

export const blogRoute = '/blog';

export const blogPostRoute = (slug) => `${blogRoute}/${slug}`;

export const campaignPageRoute = (slug) => `/campaigns/${slug}`;

export const customersRoute = '/customers';

export const customerRoute = (slug) => `${customersRoute}/${slug}`;

export const customerArchivePageRoute = (page) =>
  `${customersRoute}/archive/${page}`;

export const formatsRoute = '/product/formats';

export const formatRoute = (slug) => `${formatsRoute}/${slug}`;

export const packagesRoute = () => `/navigator`;

export const packageRoute = (registry, name) =>
  `/navigator/${registry}/${name}`;

export const packageSearchRoute = (
  registry,
  searchTerm,
  keywords,
  licenses,
  page,
) => {
  return {
    pathname: '/navigator/search',
    query: removeEmptyKeys({
      q: searchTerm,
      keywords: keywords && keywords.join(','),
      licenses: licenses && licenses.join(','),
      registry,
      page,
    }),
  };
};

export const packageKeywordSearchRoute = (registry, keyword) => {
  const keywordSearch = `${KEYWORD_OPERATOR} ${keyword.toLowerCase()}`;

  return {
    pathname: '/navigator/search',
    query: { q: keywordSearch, registry },
  };
};

export const registryRoute = (registry, page) => {
  return {
    pathname: `/navigator/${registry}`,
    query: removeEmptyKeys({
      page,
    }),
  };
};

export const pressRoomRoute = () => `/company/press`;

export const pressReleaseRoute = (slug) => `/company/press/${slug}`;

export const submitRoute = () => `/api/submit`;

export const switchPagesRoute = '/switch';

export const switchPageRoute = (slug) => `${switchPagesRoute}/${slug}`;

export const webinarsRoute = '/webinars';

export const webinarRoute = (slug) => `${webinarsRoute}/${slug}`;

export const webinarArchivePageRoute = (page) =>
  `${webinarsRoute}/archive/${page}`;

export const webinarICSRoute = (slug) => `/api/webinars/${slug}`;

export const solutionRoute = (slug) => `/solutions/${slug}`;

export const productOverviewRoute = `/product`;

export const productPageRoute = (slug) => `/product/${slug}`;
