import themeStyles from '@styles/themes.module.css';

export const colorMap = {
  blue: 'var(--color-accent-default)',
  teal: 'var(--color-teal)',
  salmon: 'var(--color-salmon)',
  yellow: 'var(--color-yellow)',
  purple: 'var(--color-purple)',
};

export const colorNames = Object.keys(colorMap);
export const defaultColor = colorNames[0];

export const themeMap = {
  light: {
    background: 'var(--color-background-default)',
    text: 'var(--color-text-default)',
    className: themeStyles.light,
  },
  dark: {
    background: 'var(--color-background-inverted)',
    text: 'var(--color-text-on-color)',
    className: themeStyles.dark,
  },
  blue: {
    background: 'var(--color-accent-default)',
    text: 'var(--color-text-on-color)',
    className: themeStyles.blue,
  },
  teal: {
    background: 'var(--color-teal)',
    text: 'var(--color-text-default)',
    className: themeStyles.teal,
  },
  salmon: {
    background: 'var(--color-salmon)',
    text: 'var(--color-text-on-color)',
    className: themeStyles.salmon,
  },
  yellow: {
    background: 'var(--color-yellow)',
    text: 'var(--color-text-default)',
    className: themeStyles.yellow,
  },
  purple: {
    background: 'var(--color-purple)',
    text: 'var(--color-text-on-color)',
    className: themeStyles.purple,
  },
};
