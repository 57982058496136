import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { isExternalHref } from '@util/url';
import { trackAnalyticsEvent } from '@util/analytics';

import Link from '@components/Link';

import ArrowRight from '@icons/ArrowRight.svg';

import * as styles from './Button.module.css';

/* eslint-disable-next-line */
const Button = React.forwardRef(
  (
    {
      variant = 'primary',
      colorScheme = 'accent',
      size = 'medium',
      children,
      href,
      className,
      fullWidth = false,
      withArrow = false,
      trackingScope,
      disabled,
      type,
      onClick,
      personalisedTopic,
      isStartFreeTrial = false,
      ...rest
    },
    forwardedRef,
  ) => {
    const cssClasses = cn(
      styles.root,
      {
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.link]: variant === 'link',
        [styles.accent]: colorScheme === 'accent',
        [styles.textColor]: colorScheme === 'text',
        [styles.dark]: colorScheme === 'dark',
        [styles.light]: colorScheme === 'light',
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
        [styles.xlarge]: size === 'xlarge',
        [styles.fullWidth]: fullWidth,
      },
      className,
    );

    const content = (
      <>
        {children}
        {withArrow && <ArrowRight className={styles.arrow} />}
        {variant === 'secondary' && (
          <>
            <span
              className={cn(styles.secondaryBorderFill, styles.topLeft)}
              aria-hidden
            />
            <span
              className={cn(styles.secondaryBorderFill, styles.topRight)}
              aria-hidden
            />
            <span
              className={cn(styles.secondaryBorderFill, styles.bottomLeft)}
              aria-hidden
            />
            <span
              className={cn(styles.secondaryBorderFill, styles.bottomRight)}
              aria-hidden
            />
          </>
        )}
      </>
    );

    const isExternalLink = isExternalHref(href);

    const handleClick = (e) => {
      const eventName = isStartFreeTrial ? 'startFreeTrial' : 'button';
      if (trackingScope) {
        trackAnalyticsEvent(eventName, {
          scope: trackingScope,
          text: children.toString(),
          href,
          personalisedTopic: personalisedTopic,
        });
      }

      if (onClick) {
        onClick(e);
      }
    };

    return href ? (
      <Link
        aria-disabled={disabled ? true : null}
        prefetch={false}
        href={href}
        className={cssClasses}
        target={isExternalLink ? '_blank' : undefined}
        rel={isExternalLink ? 'noopener noreferrer' : null}
        ref={forwardedRef}
        onClick={handleClick}
        {...rest}
      >
        {content}
      </Link>
    ) : (
      <button
        className={cssClasses}
        disabled={disabled}
        type={type}
        ref={forwardedRef}
        onClick={handleClick}
        {...rest}
      >
        {content}
      </button>
    );
  },
);

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'link']),
  colorScheme: PropTypes.oneOf(['accent', 'text', 'dark', 'light']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  fullWidth: PropTypes.bool,
  withArrow: PropTypes.bool,
  trackingScope: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  personalisedTopic: PropTypes.string,
  isStartFreeTrial: PropTypes.bool,
};

export default Button;
