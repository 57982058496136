import React, { useState, useMemo } from 'react';
import cn from 'classnames';

// As we're deliberately wrapping around next/image, we're ignoring our custom
// eslint rule here
// eslint-disable-next-line local-rules/use-custom-image
import NextImage from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';
import { getClient } from '@sanity-lib/client';

import { DEFAULT_PLACEHOLDER } from './constants';

import styles from './SanityImage.module.css';
import { containDimensions } from '@util/aspectRatio';

/**
 * A thin wrapper around next image that sets up
 * a default placeholder for images while they are
 * loading.
 */
const SanityImage = ({
  className,
  placeholder = DEFAULT_PLACEHOLDER,
  onLoad,
  asset,
  maxWidth,
  maxHeight,
  ...rest
}) => {
  const client = useMemo(() => getClient({ useCdn: true }), []);
  const sanityProps = useNextSanityImage(client, asset);

  // Handle width and height of image. No dimensions for fill.
  let width = undefined;
  let height = undefined;

  if (!rest.fill) {
    // If containing within a certain maxWidth and maxHeight
    if (maxHeight && maxWidth) {
      const resized = containDimensions({
        width: sanityProps.width,
        height: sanityProps.height,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
      });
      width = Math.round(resized.width);
      height = Math.round(resized.height);
    }
    // Otherwise use the sanity image size
    else {
      width = sanityProps.width;
      height = sanityProps.height;
    }
  }

  const imageProps = {
    ...sanityProps,
    ...{ width, height },
    ...rest,
  };

  const [loaded, setLoaded] = useState(false);

  return (
    <NextImage
      blurDataURL={placeholder}
      className={cn(styles.root, className)}
      data-loaded={loaded || null}
      placeholder="blur"
      onLoad={(e) => {
        setLoaded(true);
        onLoad?.(e);
      }}
      {...imageProps}
    />
  );
};

export default SanityImage;
