export const containDimensions = ({ width, height, maxWidth, maxHeight }) => {
  const ratio = width / height;
  const maxRatio = maxWidth / maxHeight;
  if (ratio > maxRatio) {
    return {
      width: maxWidth,
      height: maxWidth / ratio,
    };
  } else {
    return {
      width: maxHeight * ratio,
      height: maxHeight,
    };
  }
};

export const orientationClassNames = ({
  width,
  height,
  square,
  landscape,
  portrait,
}) => {
  const ratio = width / height;

  if (isNaN(ratio)) return;

  if (square && ratio > 0.95 && ratio < 1.1) {
    return square;
  } else if (landscape && ratio > 1.1) {
    return landscape;
  } else if (portrait && ratio < 0.95) {
    return portrait;
  }
};
