import { createClient } from '@sanity/client';

import {
  apiVersion,
  dataset,
  projectId,
  useCdn,
  readToken,
} from '@sanity-defs/env';

export const getClient = (options) => {
  const preview = options?.preview ?? false;
  const useCdn = options?.useCdn ?? false;
  return createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn,
    perspective: preview ? 'previewDrafts' : 'published',
    token: readToken,
  });
};

export const getWriteClient = (token) =>
  createClient({
    apiVersion,
    dataset,
    projectId,
    useCdn,
    perspective: 'raw',
    token,
  });
