/**
 * The integrations root route
 */
export const integrationsRoute = '/product/integrations';

/**
 * Route to a single integration
 * @param{string} slug
 * @returns {string}
 */
export const integrationRoute = (slug) => `${integrationsRoute}/${slug}`;
