export const match = (...options) => {
  for (const [predicate, value] of options) {
    if (predicate) return value;
  }

  return null;
};

export const removeEmptyKeys = (obj) => {
  const newObj = {};
  for (const key in obj) {
    if (obj[key] !== null && typeof obj[key] !== 'undefined') {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};
