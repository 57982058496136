import React, { useState } from 'react';
import cn from 'classnames';

// As we're delibaretly wrapping around next/image, we're ignoring our custom
// eslint rule here
// eslint-disable-next-line local-rules/use-custom-image
import NextImage from 'next/image';

import { DEFAULT_PLACEHOLDER } from './constants';

import styles from './Image.module.css';

/**
 * A thin wrapper around next image that sets up
 * a default placeholder for images while they are
 * loading.
 */
const Image = ({
  className,
  placeholder = DEFAULT_PLACEHOLDER,
  onLoad,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <NextImage
      blurDataURL={placeholder}
      className={cn(styles.root, className)}
      data-loaded={loaded || null}
      placeholder="blur"
      onLoad={(e) => {
        setLoaded(true);
        onLoad?.(e);
      }}
      {...rest}
    />
  );
};

export default Image;
