import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ArrowRight from '@icons/ArrowRight.svg';

import Link from '@components/Link';
import textStyles from '@styles/textStyles.module.css';
import ThirdPartyLogo from '@components/ThirdPartyLogo';

import { trackAnalyticsEvent } from '@util/analytics';

import * as styles from './CardWithLogo.module.css';

const CardWithLogo = ({
  href,
  title,
  text,
  logo,
  titleTag = 'div',
  trackingScope,
}) => {
  const hasLink = href && href.length > 0;
  const TitleComponent = titleTag;

  const inner = (
    <>
      <header>
        <TitleComponent className={cn(styles.title)}>{title}</TitleComponent>
        {text && <p className={cn(styles.text, textStyles.bodyS)}>{text}</p>}
      </header>
      <footer className={cn(styles.footer)}>
        {logo && <ThirdPartyLogo logoKey={logo} className={cn(styles.logo)} />}
        {hasLink && <ArrowRight className={cn(styles.arrowIcon)} />}
      </footer>
    </>
  );

  const handleClick = () => {
    if (trackingScope) {
      trackAnalyticsEvent('CardWithLogo', {
        scope: trackingScope,
        text: title,
        href,
      });
    }
  };

  return hasLink ? (
    <Link
      prefetch={false}
      href={href}
      className={cn(styles.root, styles.hasLink)}
      onClick={handleClick}
    >
      {inner}
    </Link>
  ) : (
    <div className={cn(styles.root)}>{inner}</div>
  );
};

CardWithLogo.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  logo: PropTypes.string.isRequired,
  href: PropTypes.string,
  titleTag: PropTypes.string,
};

export default CardWithLogo;
